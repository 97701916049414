body {
  position: relative;
  margin: 0;
  padding: 30px 0px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: black; */
  background-image: url('/public/images/main-egg.png');
  background-size: 80px 80px;
  background-repeat: repeat;
  opacity: 0.5;
  z-index: -1;
}


.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  color: rgb(48, 15, 14);
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

.navbar {
  background-color:yellow;
  padding: 20px 30px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.navbar a {
  color: rgb(48, 15, 14);
  text-decoration: none;
  margin: 0 10px;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 700;
  margin: 20px;
}

.navbar a:hover {
  color: rgb(124, 82, 22);
}

.main-image {
  width: 350px;
  height: 350px;
  border-radius: 20px;
  margin-bottom: 30px;
  margin-top: 40px;
  border: 5px solid yellow;
  background-color: rgba(53, 53, 50, 0.5);
}

.button {
  background-color: yellow;
  color: black;
  font-size: 20px;
  font-weight: 700;
  padding: 20px 25px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
}

.button:hover {
  background-color: #e7e437;
}

.address {
  font-size: 22px;
  margin-top: 20px;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.social-icons img {
  width: 40px;
  height: 40px;
  background-color: rgb(77, 77, 18);
  padding: 10px;
  border-radius: 10px;
  transition: background-color 0.3s;
}

.social-icons img:hover {
  background-color: #aa681d;
  cursor: pointer;
}
